import React from 'react'
import { BrowserRouter as Router , Routes ,Route } from 'react-router-dom'
import Header from './Components/Header'
import './App.css'
import Home from './Pages/Home'
import WholeSal from './Pages/WholeSal'
import Products from './Pages/Products'
// import ProductDetail from './Pages/ProductDetail'
const App = () => {
  return (
   <Router>
    <Header/>
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/page/:wholeId' element={<WholeSal/>} />
      <Route path='/our_products' element={<Products/>} />
      <Route path="/category/:categoryId" element={<Products />} /> {/* New route for category */}
    </Routes>
   </Router>
  )
}

export default App