/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
// import homeImag1 from "../assets/misc images/aromatherapy_Panda.jpg";
// import homeImag2 from "../assets/Diffusers/lavender-oil.jpg";
// import homeImag3 from "../assets/smuding-sage-and-bowl/Rose-Petals Blue-Sage.jpg";
// import homeImag4 from "../assets/smuding-sage-and-bowl/Dragons_Blood_Smudge_Bundle.jpg";
// import homeImag5 from "../assets/smuding-sage-and-bowl/Sage and Bowl.jpeg";
// import homeImag6 from "../assets/Diffusers/Skin-&-Body-Oil.jpg";
// import homeImag7 from "../assets/Diffusers/bodyoils-sm.jpg";
import homeImag8 from "../assets/Diffusers/teatreeessential.jpg";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
// import api from "../Axios/AxiosInterceptor";
import axios from "axios";
// import AddToCartPopup from "../Components/add-to-cart";
import { ThemeContext } from "../context/CardContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Footer from "../Components/Footer";
const Products = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [showForm, setshowForm] = useState(false);
  const [nameP, setNameP] = useState("");
  const [phoneP, setPhoneP] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedVariations, setSelectedVariations] = useState({});

  const { setcarts, carts } = useContext(ThemeContext);

  const [top_headerP, setTopHeaderP] = useState([]);
  const [titleP, setPtitle] = useState([]);
  const [Ptext1, setPtext1] = useState([]);
  const [Pbg_image, setpbg_image] = useState([]);
  const [Pimage1, setPimage1] = useState([]);
  const [Ptext2, setPtext2] = useState([]);
  const [Ptext3, setPtext3] = useState([]);
  const [Pimage2, setPimage2] = useState([]);
  const [Pimage3, setPimage3] = useState([]);
  const [Ptext4, setPtext4] = useState([]);
  const [Ptext5, setPtext5] = useState([]);
  const [Ptext6, setPtext6] = useState([]);
  const [Ptext7, setPtext7] = useState([]);
  const [products, setProducts] = useState([]);
  console.log("dddadasd", products);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const [emailP, setEmailP] = useState("");
  const [ProductStyle, setProductStyle] = useState("");
  const [quantity, setQuantity] = useState(1); // Quantity state
  useEffect(() => {
    axios
      .get(
        `https://puregoldapi.dev-mn.xyz/api/user/get/single/category/content/by/category/${categoryId}`
      )
      .then((res) => {
        setTopHeaderP(res.data.data.top_header);
        setPtitle(res.data.data.title);
        setPtext1(res.data.data.text1);
        setpbg_image(res.data.data.bg_image);
        setPimage1(res.data.data.image1);
        setPtext2(res.data.data.text2);
        setPtext3(res.data.data.text3);
        setPimage2(res.data.data.image2);
        setPimage3(res.data.data.image3);
        setPtext4(res.data.data.text4);
        setPtext5(res.data.data.text5);
        setPtext6(res.data.data.text6);
        setPtext7(res.data.data.text7);
        setProducts(res.data.data.products);
        console.log("getIdCat", res.data.data);
      })
      .catch((err) => {
        console.error("Error fetching products:", err);
      });
  }, [categoryId]);

  console.log(selectedVariations, "selectedVariations");
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", nameP);
    formData.append("email", emailP);
    formData.append("phone", phoneP);

    selectedVariations?.forEach((product, index) => {
      formData.append(`products[${index}][product_id]`, product?.productId);
      formData.append(`products[${index}][variation_id]`, product?.variationId);
      formData.append(`products[${index}][quantity]`, quantity);
    });

    axios
      .post("https://puregoldapi.dev-mn.xyz/api/user/check/out/post", formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setshowForm(false);
          navigate("/");
          // alert('Success: ' + res.data.message); // Display success message
          // You can also update state or perform additional actions here
        }
      })
      .catch((err) => {
        // Error handling
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
          // alert('Error: ' + err.response.data.message); // Display error message
        } else {
          alert("An unexpected error occurred.");
        }
        console.log(
          "Error in POST API:",
          err.response ? err.response.data.message : err.message
        );
      });
  };

  console.log(selectedVariations, "selectedVariations");
  // const navigate = useNavigate();
  // const { categoryId } = useParams();
  // const [products, setProducts] = useState([]);
  // const navigate = useNavigate();
  // const { categoryId } = useParams();
  // const [products, setProducts] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [cartItems, setCartItems] = useState([]);

  const handleAddToCart = (product) => {
    toast.success("Cart Add SuccessFully");
    setcarts([...carts, product]);
    console.log(product, "product---->");
    setOpenDropdown(null);
  };

  const handleVariationChange = (productId, variationId, isChecked) => {
    setSelectedVariations((prev) => {
      const updated = { ...prev };
      if (isChecked) {
        if (!updated[productId]) updated[productId] = [];
        updated[productId].push(variationId);
      } else {
        updated[productId] =
          updated[productId]?.filter((id) => id !== variationId) || [];
        if (updated[productId].length === 0) delete updated[productId];
      }
      return updated;
    });
  };
  const productName = localStorage.getItem("productName");
  const fetchStylePage = async () => {
    console.log(productName, "productName--->");
    const formdata = new FormData();
    formdata.append("name", productName);

    try {
      const response = await axios.post(
        `https://puregoldapi.dev-mn.xyz/api/user/get/dynamic/product/page/layout`,
        formdata
      );

      const data = response?.data?.data; // Axios already parses the response as JSON
      console.log("bgimagresponsee--->", data);

      if (data) {
        // Assuming your API response contains a "file_path" field
        setProductStyle(data);
        // You can use the response to set the style as needed
      }
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchStylePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productName]);

  console.log(ProductStyle, "productName--->");
  return (
    <div>
      <div
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",

          backgroundImage: `url(${homeImag8})`,
          // objectFit: "contain",
        }}
        className="mb-0 h-[828px] md:h-[736] relative top-20"
      >
        <div className="relative flex items-center justify-center">
          <div
            className="  w-[93%] mt-[84px] md:ml-[52px]  flex gap-[5px] flex-wrap"
            style={{ position: "relative" }}
          >
            {/* Images */}
            <div
              className="w-full mb-3 md:w-[90%] flex justify-center items-center"
              style={{
                padding: "16px",
                // marginLeft: "-32px",
                marginTop: "-60px",
                textAlign: "center",
                background:
                  Array.isArray(ProductStyle) &&
                  ProductStyle.find(
                    (style) => style?.type === "DynamicProductTopHeader"
                  )
                    ? ProductStyle.find(
                        (style) => style?.type === "DynamicProductTopHeader"
                      )?.background
                    : "#693948",
              }}
            >
              <p
                style={{
                  color:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductTopHeader"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductTopHeader"
                        )?.color
                      : "white",
                  fontFamily:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductTopHeader"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductTopHeader"
                        )?.font_family
                      : "",
                  fontSize:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductTopHeader"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductTopHeader"
                        )?.font_size
                      : "12px",
                  margin: 0,
                }}
                className="md:text-[17px] text-[12px]"
              >
                {top_headerP}
              </p>
            </div>
            <div
              className="w-full mb-3 md:-ml-[32px]"
              style={{
                padding: "22px",
                // marginLeft: "-32px",
                marginTop: "2px",
                textAlign: "center",
                background:
                  Array.isArray(ProductStyle) &&
                  ProductStyle.find(
                    (style) => style?.type === "DynamicProductTitle"
                  )
                    ? ProductStyle.find(
                        (style) => style?.type === "DynamicProductTitle"
                      )?.background
                    : "#693948",
              }}
            >
              <p
                style={{
                  alignItems: "center",
                  fontWeight: "bold",
                  margin: 0,
                  color:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductTitle"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductTitle"
                        )?.color
                      : "white",
                  fontFamily:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductTitle"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductTitle"
                        )?.font_family
                      : "",
                  fontSize:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductTitle"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductTitle"
                        )?.font_size
                      : "12px",
                }}
                className="md:text-[36px]"
              >
                {titleP}
              </p>
            </div>

            <div className="flex flex-col md:flex-row gap-6 w-full max-w-6xl mx-auto px-4">
              {/* Text Container */}
              <div
                style={{
                  background:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductText1"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductText1"
                        )?.background
                      : "#4a7232cc",
                }}
                className="w-full md:w-1/2 md:text-[12px] p-3 py-4 md:p-8 order-2 md:order-1"
              >
                <p
                  style={{
                    color:
                      Array.isArray(ProductStyle) &&
                      ProductStyle.find(
                        (style) => style?.type === "DynamicProductText1"
                      )
                        ? ProductStyle.find(
                            (style) => style?.type === "DynamicProductText1"
                          )?.color
                        : "white",
                    fontFamily:
                      Array.isArray(ProductStyle) &&
                      ProductStyle.find(
                        (style) => style?.type === "DynamicProductText1"
                      )
                        ? ProductStyle.find(
                            (style) => style?.type === "DynamicProductText1"
                          )?.font_family
                        : "",
                    fontSize:
                      Array.isArray(ProductStyle) &&
                      ProductStyle.find(
                        (style) => style?.type === "DynamicProductText1"
                      )
                        ? ProductStyle.find(
                            (style) => style?.type === "DynamicProductText1"
                          )?.font_size
                        : "12px",
                  }}
                  className="text-center md:text-left text-[12px] md:text-lg md:leading-[33px] lg:text-[18px] leading-relaxed"
                >
                  {Ptext1}
                </p>
              </div>

              {/* Image Container */}
              <div className="w-full md:w-1/2 h-[240px] md:h-[442px] order-1 md:order-2">
                <img
                  src={Pimage1}
                  alt="Product showcase"
                  className="w-full  h-full object-cover rounded-lg shadow-lg"
                />
              </div>
            </div>

            {/* ------------------------------ */}

            {/* Text */}
            <div
              style={{
                position: "absolute",
                top: "49%", // Adjust vertical positioning
                left: "40%", // Center horizontally
                transform: "translate(-50%, -50%)", // Center perfectly
                zIndex: 10, // Ensure it's above images
                textAlign: "center",
              }}
            ></div>
          </div>
        </div>
      </div>

      <div className="relative  py-7 px-4">
        <div className="absolute inset-0 z-0"></div>
      </div>

      <div
        className="w-full"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundImage: `url(${Pbg_image})`,
          // "url('https://img.pagecloud.com/IkjOSVk8YuJ-2vXm7s-Gs61Q63c=/4200x0/filters:no_upscale()/puregoldfragrancecom/images/sheabutter-farmersmarket-4-n9f13.jpg')",
        }}
      >
        <div
          style={{
            background:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText2"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText2"
                  )?.background
                : "#DDDFCF", // Default background color
          }}
          className="mt-[20px] text-center  md:mx-[88px] py-2 px-4"
        >
          <p
            style={{
              color:
                Array.isArray(ProductStyle) &&
                ProductStyle.find(
                  (style) => style?.type === "DynamicProductText2"
                )
                  ? ProductStyle.find(
                      (style) => style?.type === "DynamicProductText2"
                    )?.color
                  : "white",
              fontFamily:
                Array.isArray(ProductStyle) &&
                ProductStyle.find(
                  (style) => style?.type === "DynamicProductText2"
                )
                  ? ProductStyle.find(
                      (style) => style?.type === "DynamicProductText2"
                    )?.font_family
                  : "",
              fontSize:
                Array.isArray(ProductStyle) &&
                ProductStyle.find(
                  (style) => style?.type === "DynamicProductText2"
                )
                  ? ProductStyle.find(
                      (style) => style?.type === "DynamicProductText2"
                    )?.font_size
                  : "20px",
            }}
            className="text-xl text-center md:text-2xl max-w-4xl mx-auto leading-relaxed"
          >
            {Ptext2}
          </p>
        </div>

        <div
          style={{
            background:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText3"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText3"
                  )?.background
                : "#B1976D", // Default background color
          }}
          className="text-center  md:mx-[88px] py-6 px-4"
        >
          <p 
           style={{
            color:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText3"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText3"
                  )?.color
                : "black",
            fontFamily:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText3"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText3"
                  )?.font_family
                : "",
            fontSize:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText3"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText3"
                  )?.font_size
                : "32px",
          }}
          className="text-xl md:text-4xl font-bold text-center max-w-4xl mx-auto leading-relaxed">
            {Ptext3}
          </p>
        </div>

        <div
          style={{ marginTop: "91px", borderRadius: "0px" }}
          className="md:mt-[91px]  flex justify-center items-center"
        >
          <img
            src={Pimage2}
            alt="Essential Oils with Lavender"
            className="w-[996px] md:mx-[93px] h-[493px]  object-cover "
          />
        </div>
        <div
          style={{ marginTop: "91px", borderRadius: "0px" }}
          className="justify-center flex-col flex  "
        >
          <img
            src={Pimage3}
            alt="Essential Oils with Lavender"
            className="md:w-[436px] md:mx-[33%]  h-[493px] object-cover "
          />
          <p 
           style={{
            color:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText4"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText4"
                  )?.color
                : "#ce6060",
            fontFamily:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText4"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText4"
                  )?.font_family
                : "",
            fontSize:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText4"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText4"
                  )?.font_size
                : "42px",
          }}
          className="text-center text-[27px] md:text-[47px] mt-7 font-bold  py-3">
            {Ptext4}
          </p>
        </div>
        <div className="relative py-12">
          <div className="md:max-w-6xl md:mx-auto md:px-4 grid md:grid-cols-2 gap-8 items-start">
            {/* Welcome Text */}
            <div 
            className=" md:flex md:justify-center md:items-center md:flex-col md:ml-[55px]  mt-[33px] text-[18px] md:w-[178%] backdrop-blur p-3">
              {Ptext5&&(
              <h1
              style={{
                background:
                  Array.isArray(ProductStyle) &&
                  ProductStyle.find(
                    (style) => style?.type === "DynamicProductText5"
                  )
                    ? ProductStyle.find(
                        (style) => style?.type === "DynamicProductText5"
                      )?.background
                    : "#4a7232cc", // Default background color
                color:
                  Array.isArray(ProductStyle) &&
                  ProductStyle.find(
                    (style) => style?.type === "DynamicProductText5"
                  )
                    ? ProductStyle.find(
                        (style) => style?.type === "DynamicProductText5"
                      )?.color
                    : "white",
                fontFamily:
                  Array.isArray(ProductStyle) &&
                  ProductStyle.find(
                    (style) => style?.type === "DynamicProductText5"
                  )
                    ? ProductStyle.find(
                        (style) => style?.type === "DynamicProductText5"
                      )?.font_family
                    : "",
                fontSize:
                  Array.isArray(ProductStyle) &&
                  ProductStyle.find(
                    (style) => style?.type === "DynamicProductText5"
                  )
                    ? ProductStyle.find(
                        (style) => style?.type === "DynamicProductText5"
                      )?.font_size
                    : "24px",
              }}
               className=" md:text-2xl p-4 md:text-[24px] font-bold text-center tracking-wide">
                {Ptext5}
              </h1>
              )}
              {Ptext6&&(
              <p
                style={{
                  background:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductText6"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductText6"
                        )?.background
                      : "#4a7232cc", // Default background color
                  color:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductText6"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductText6"
                        )?.color
                      : "white",
                  fontFamily:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductText6"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductText6"
                        )?.font_family
                      : "",
                  fontSize:
                    Array.isArray(ProductStyle) &&
                    ProductStyle.find(
                      (style) => style?.type === "DynamicProductText6"
                    )
                      ? ProductStyle.find(
                          (style) => style?.type === "DynamicProductText6"
                        )?.font_size
                      : "18px",
                      wordSpacing: "2px" 
                }}
                className="p-6 md:text-[18px] text-[13px] leading-8 md:leading-[34px] pt-[26px] pb-[26px] mx-[7px]"
              >
                {Ptext6}
              </p>
              )}
            </div>
          </div>
          <div className="w-[80%] mx-auto items-center justify-center flex flex-wrap gap-6">
            {showForm && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
                  <h2 className="text-2xl font-bold mb-4">Add to Cart</h2>
                  <form className="space-y-4" onSubmit={handleFormSubmit}>
                    <div>
                      <label className="block text-sm font-semibold mb-1">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        value={nameP}
                        onChange={(e) => setNameP(e.target.value)}
                        required
                        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-semibold mb-1">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={emailP}
                        onChange={(e) => setEmailP(e.target.value)}
                        required
                        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-semibold mb-1">
                        Quantity
                      </label>
                      <input
                        type="number"
                        name="quantity"
                        min="1"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        required
                        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-semibold mb-1">
                        Phone
                      </label>
                      <input
                        type="number"
                        name="phone"
                        value={phoneP}
                        onChange={(e) => setPhoneP(e.target.value)}
                        required
                        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    <div className="flex justify-end gap-2 mt-4">
                      <button
                        type="button"
                        onClick={() => setshowForm(false)}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                      >
                        Add to Cart
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {products?.map((p) => {
              const Images = JSON.parse(p?.image);
              return (
                <div
                  key={p.id}
                  className="flex flex-col w-[300px] bg-white/90 shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-300 ease-in-out rounded-lg overflow-hidden border border-gray-200 hover:bg-white/70"
                >
                  {/* Swiper Slider for Images */}
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    spaceBetween={10}
                    navigation={true}
                    pagination={{ clickable: true }}
                    modules={[Navigation]}
                    className="mySwiper"
                  >
                    {Images?.map((ImageItem, index) => (
                      <SwiperSlide key={index}>
                        <img
                          src={ImageItem || ""}
                          alt={`Product Image ${index + 1}`}
                          style={{
                            height: "300px",
                            width: "100%",
                            backgroundSize: "100% 100%",
                          }}
                          className="h-[230px] w-full object-cover"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <div className="p-4 space-y-4">
                    <h1 className="text-lg font-semibold text-[#693948]">
                      {p.title}
                    </h1>
                    <p className="text-sm text-gray-600 leading-relaxed">
                      {p.description}
                    </p>
                    <div className="flex justify-between items-center">
                      <h3 className="font-semibold text-[17px] text-[#693948]">
                        ${p.price}
                      </h3>
                      <div className="py-2 px-4 border rounded-md bg-white/50">
                        <div className="relative inline-block w-full">
                          {/* Button to toggle the dropdown */}
                          <button
                            className="py-2 px-4 w-[150px] border rounded-md bg-white/50 focus:ring-2 focus:ring-blue-400"
                            onClick={() =>
                              setOpenDropdown(
                                openDropdown === p.id ? null : p.id
                              )
                            }
                          >
                            Select
                          </button>

                          {/* Dropdown content */}
                          {openDropdown === p.id && (
                            <div
                              className="left-0 h-[118px] overflow-scroll mt-1 w-full bg-white border rounded-md shadow-lg z-10"
                              style={{ overflowY: "auto" }}
                            >
                              {p.variations.map((variation) => (
                                <label
                                  key={variation.id}
                                  className="flex items-center space-x-2 p-2 hover:bg-blue-100"
                                >
                                  <input
                                    type="checkbox"
                                    value={variation.id}
                                    onChange={(e) =>
                                      handleVariationChange(
                                        p.id,
                                        variation.id,
                                        e.target.checked
                                      )
                                    }
                                    className="focus:ring-2 focus:ring-blue-400"
                                  />
                                  <span>{variation.value}</span>
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <button
                        className="add-to-cart-btn"
                        onClick={() => {
                          if (openDropdown === p.id) {
                            const selectedVariationsForProduct =
                              selectedVariations[p.id] || [];
                            const selectedVariationsnew = p.variations.filter(
                              (variation) =>
                                selectedVariationsForProduct.includes(
                                  variation.id
                                )
                            );

                            if (selectedVariationsnew.length > 0) {
                              handleAddToCart({
                                id: p.id,
                                title: p.title,
                                price: p.price,
                                image: p.image,
                                variations: selectedVariationsnew,
                                quantity: 1,
                              });
                            } else {
                              toast.error(
                                "Please select at least one variation"
                              );
                            }
                          } else {
                            toast.error("Please select a variation");
                          }
                        }}
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <p 
           style={{
            background:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText7"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText7"
                  )?.background
                : "#D8D3C4", // Default background color
            color:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText7"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText7"
                  )?.color
                : "#4a7232cc",
            fontFamily:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText7"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText7"
                  )?.font_family
                : "",
            fontSize:
              Array.isArray(ProductStyle) &&
              ProductStyle.find(
                (style) => style?.type === "DynamicProductText7"
              )
                ? ProductStyle.find(
                    (style) => style?.type === "DynamicProductText7"
                  )?.font_size
                : "24px",
          }}
          className="md:text-[20px] p-9 text-center mt-9">
            {Ptext7}
          </p>
          {/* <div class="grid grid-cols-1 md:grid-cols-2 gap-12 items-center"> */}
        </div>
      </div>
      <div className="cart">
        {cartItems?.length > 0 && <h2>Cart</h2>}
        <ul>
          {cartItems.map((item, index) => (
            <li key={index}>
              {item.name} - {item.price}
            </li>
          ))}
        </ul>
      </div>
      {/* <footer className="bg-[#6E8787] text-white py-10">
        <div className="container mx-auto flex flex-col items-center">
          <div className="flex space-x-6 mb-4">
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="YouTube"
            >
              <FaYoutube />
            </a>
          </div>
          <p className="text-lg font-semibold">Pure Gold Fragrances</p>
          <div className="flex space-x-8 mt-4">
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Home
            </a>
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Wholesale
            </a>
          </div>
        </div>
      </footer> */}
      <Footer />
    </div>
  );
};

export default Products;
