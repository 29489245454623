/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import api from "../Axios/AxiosInterceptor";

const Footer = () => {
  const [Dynamicpages, setDynamicpages] = useState([]);
  const [pagestyle, setpagestyle] = useState([]);
  const fetchpagestyle = async () => {
    try {
      const response = await api.get("user/get/home/layout");
      const headerData = response.data.data.filter((item) => item.type === "footer");
      setpagestyle(headerData);
      console.log(response?.data?.data, "layout--->");
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
  };

  useEffect(() => {
    axios
      .get("https://puregoldapi.dev-mn.xyz/api/user/get/footer/links")
      .then((res) => {
        setDynamicpages(res?.data?.data);
        console.log(res?.data?.data, "res?.data---->");
      })
      .catch((err) => {
        console.error("Error fetching other data:", err);
      });
      fetchpagestyle()
  }, []);

  // Function to render social media icons with or without links
  const renderSocialIcons = () => {
    const socialLinks = {
      facebook: "",
      instagram: "",
      youtube: "",
    };

    // Loop through Dynamicpages to set available links
    Dynamicpages.forEach((page) => {
      if (page.type === "facebook") socialLinks.facebook = page.link;
      if (page.type === "instagram") socialLinks.instagram = page.link;
      if (page.type === "youtube") socialLinks.youtube = page.link;
      console.log(page.link,'links');
    });

    return (
      <>
        <a
          href={socialLinks.facebook ? `https://${socialLinks.facebook}` : "#"}
          className="text-white text-3xl hover:text-gray-300"
          target="_blank"
          aria-label="Facebook" rel="noreferrer"
        >
          <FaFacebookF />
        </a>
        <a
          href={socialLinks.instagram ? `https://${socialLinks.instagram}` : "#"}
          className="text-white text-3xl hover:text-gray-300"
          target="_blank"
          aria-label="Instagram" rel="noreferrer"
        >
          <FaInstagram />
        </a>
        <a
          href={socialLinks.youtube ? `https://${socialLinks.youtube}` : "#"}
          className="text-white text-3xl hover:text-gray-300"
          target="_blank"
          aria-label="YouTube" rel="noreferrer"
        >
          <FaYoutube />
        </a>
      </>
    );
  };

  return (
    <>
      <footer 
       style={{
        ...(pagestyle?.find(
          (style) => style?.type === "footer"
        ) && {
          background:
            pagestyle.find(
              (style) => style.type === "footer"
            )?.background || "#29444D", 
        }),
      }}
      className="bg-[#6E8787] text-white py-10">
        <div className="container mx-auto flex flex-col items-center">
          <div className="flex space-x-6 mb-4">
            {/* Render social media icons dynamically */}
            {renderSocialIcons()}
          </div>
          <p  style={{
                  ...(pagestyle?.find(
                    (style) => style?.type === "footer"
                  ) && {
                    color:
                      pagestyle.find(
                        (style) => style.type === "footer"
                      )?.color || "#fff", // Default text color
                    fontSize: `${
                      pagestyle.find(
                        (style) => style.type === "footer"
                      )?.font_size || 18 // Default font size
                    }px`,
                    fontFamily:
                      pagestyle.find(
                        (style) => style.type === "footer"
                      )?.font_family || "", // Default font family
                  }),
                }}
            className="sm:text-base font-semibold">Pure Gold Fragrances</p>
          <div className="flex space-x-8 mt-4">
            {/* <a href="#" className="text-sm text-white hover:text-gray-300">
              Home
            </a>
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Wholesale
            </a> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
