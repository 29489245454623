import React, { useContext, useEffect, useState } from "react";
import { Navbar, Dropdown } from "flowbite-react";
import { Link } from "react-router-dom";
import Logo from "../assets/misc images/perfumeLogo.png";
import { LuBaggageClaim } from "react-icons/lu";
import axios from "axios";
import CartModal from "./Modal/ModalForCart";
import { ThemeContext } from "../context/CardContext";
import api from "../Axios/AxiosInterceptor";

const Header = () => {
  const { carts } = useContext(ThemeContext);
  const [categories, setCategories] = useState([]);
  const [Dynamicpages, setDynamicpages] = useState([]);
  const [NewLogo, setNewLogo] = useState("");
  const [showmodal, setshowmodal] = useState(false);
  const handleClose = () => setshowmodal(false);
  const handleShow = () => setshowmodal(true);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleNavbarToggle = () => setIsNavbarOpen((prev) => !prev);
  const closeNavbar = () => setIsNavbarOpen(false);

  const [pagestyle, setpagestyle] = useState([]);
  const fetchpagestyle = async () => {
    try {
      const response = await api.get("user/get/home/layout");
      const headerData = response.data.data.filter(
        (item) => item.type === "header"
      );
      setpagestyle(headerData);
      console.log(response?.data?.data, "layout--->");
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
  };

  useEffect(() => {
    axios
      .get("https://puregoldapi.dev-mn.xyz/api/user/get/all/categories")
      .then((res) => {
        setCategories(res?.data?.data);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
      });

    axios
      .get("https://puregoldapi.dev-mn.xyz/api/user/get/logo")
      .then((res) => {
        setNewLogo(res?.data?.data?.image);
      })
      .catch((err) => {
        console.error("Error fetching other data:", err);
      });
    axios
      .get("https://puregoldapi.dev-mn.xyz/api/user/get/all/page/copies")
      .then((res) => {
        setDynamicpages(res?.data?.data);
        console.log(res?.data?.data, "res?.data---->");
      })
      .catch((err) => {
        console.error("Error fetching other data:", err);
      });
    fetchpagestyle();
  }, []);

  const totalLength =
    carts?.reduce((sum, cart) => {
      if (!cart?.variations || !Array.isArray(cart.variations)) {
        return sum;
      }
      return sum + cart?.variations?.length;
    }, 0) || 0;

  const [activeLink, setActiveLink] = useState("/");

  const handleLinkClick = (link) => {
    setActiveLink(link);
    closeNavbar(); // Assuming this closes the navbar
  };
  const headerStyles =
    pagestyle?.find((style) => style.type === "header") || {};
  console.log(pagestyle, "pagestylepages--->");
  return (
    <div
      style={{
        ...(pagestyle?.find((style) => style?.type === "header") && {
          background:
            pagestyle.find((style) => style.type === "header")?.background ||
            "#29444D",
        }),
      }}
      className="p-2 text-white fixed top-0 w-full z-50"
    >
      <Navbar
        style={{
          background:
            pagestyle?.find((style) => style?.type === "header")?.background ||
            "#29444D",
        }}
        className="text-white"
        fluid
        rounded
      >
        <Navbar.Brand as={Link} to="/">
          <img
            src={NewLogo !== "" ? NewLogo : Logo}
            className="mr-3 w-40 sm:w-[256px] sm:h-[54px] object-contain"
            alt="Logo"
          />
        </Navbar.Brand>

        <div
          onClick={handleShow}
          className="flex relative h-6 sm:h-8 items-center cursor-pointer md:hidden"
        >
          <LuBaggageClaim size={20} />
          <p className="absolute left-5 text-xs sm:text-sm top-[-4px]">
            {totalLength || 0}
          </p>
        </div>

        <Navbar.Toggle onClick={handleNavbarToggle} />

        <Navbar.Collapse
          className={`sm:flex-row ${isNavbarOpen ? "block" : "hidden"}`}
        >
          {/* Home Link */}
          <Navbar.Link
            style={{
              fontSize: `${
                pagestyle?.find((style) => style.type === "header")
                  ?.font_size || 18
              }px`,
              fontFamily:
                pagestyle?.find((style) => style.type === "header")
                  ?.font_family || "",
              color:
                activeLink === "/"
                  ? "black"
                  : pagestyle?.find((style) => style.type === "header")
                      ?.color || "#fff",
            }}
            className="sm:text-sm cursor-pointer"
          >
            <Link to="/" onClick={() => handleLinkClick("/")}>
              Home
            </Link>
          </Navbar.Link>

          {/* Dynamic Pages */}
          {Dynamicpages?.map((Pages) => (
            <Navbar.Link
              key={Pages?.id}
              style={{
                fontSize: `${
                  pagestyle?.find((style) => style.type === "header")
                    ?.font_size || 18
                }px`,
                fontFamily:
                  pagestyle?.find((style) => style.type === "header")
                    ?.font_family || "",
                color:
                  activeLink === `/page/${Pages?.id}`
                    ? "black"
                    : pagestyle?.find((style) => style.type === "header")
                        ?.color || "#fff",
              }}
              className="text-sm sm:text-lg cursor-pointer"
            >
              <Link
                to={`/page/${Pages?.id}`}
                onClick={() => {
                  handleLinkClick(`/page/${Pages?.id}`);
                  localStorage.setItem("pageName", Pages?.name);
                }}
              >
                {Pages?.name}
              </Link>
            </Navbar.Link>
          ))}

          {/* Dropdown for Categories */}
          <Dropdown
            className="bg-gray-300"
            label={
              <span
                style={{
                  fontSize: `${headerStyles.font_size || 18}px`,
                  fontFamily: headerStyles.font_family || "",
                  color: headerStyles.color || "#fff",
                }}
              >
                Our Products
              </span>
            }
            inline
          >
            {categories?.map((category) => (
              <Dropdown.Item
                key={category?.id}
                style={{
                  fontSize: `${
                    pagestyle?.find((style) => style.type === "header")
                      ?.font_size || 18
                  }px`,
                  fontFamily:
                    pagestyle?.find((style) => style.type === "header")
                      ?.font_family || "",
                  color:
                    activeLink === `/category/${category?.id}`
                      ? "black"
                      : pagestyle?.find((style) => style.type === "header")
                          ?.color || "#fff",
                }}
                className="cursor-pointer bg-gray-300"
                as={Link}
                to={`/category/${category?.id}`}
                onClick={() => {
                  handleLinkClick(`/category/${category?.id}`);
                  localStorage.setItem("productName", category?.name);
                }}
              >
                {category?.name}
              </Dropdown.Item>
            ))}
          </Dropdown>

          {/* Baggage Claim Icon */}
          <div
            onClick={handleShow}
            className="relative h-6 sm:h-8 items-center cursor-pointer md:flex hidden"
          >
            <LuBaggageClaim size={20} />
            <p className="absolute left-5 text-xs sm:text-sm top-[-4px]">
              {totalLength || 0}
            </p>
          </div>
        </Navbar.Collapse>
      </Navbar>

      <CartModal showmodal={showmodal} handleClose={handleClose} />
    </div>
  );
};

export default Header;
