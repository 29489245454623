/* eslint-disable eqeqeq */
import React, { useState, useContext } from "react";
import { ThemeContext } from "../../context/CardContext";
import toast from "react-hot-toast";
import axios from "axios";

const CartModal = ({ showmodal, handleClose }) => {
  const { carts, setcarts } = useContext(ThemeContext);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  if (!showmodal) return null;
  const weight = carts?.map((cart) =>
    cart?.variations?.map((variation) => variation?.weight)
  );

  console.log(weight, "weight---->");
  const handleCheckoutClick = () => {
    // const isWeightValid = weight
    //   .flat()
    //   .every((item) => item !== null && item !== undefined && item !== "");

    setShowCheckoutForm(true);
    // if (isWeightValid) {
    // } else {
    //   toast.error("Please fill in all the weight fields");
    // }
  };

  const handleBackToCart = () => {
    setShowCheckoutForm(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    let variantIndex = 0;
    carts?.forEach((product) => {
      if (Array.isArray(product?.variations)) {
        product?.variations.forEach((e) => {
          const ozValue = e?.ozInput;
          formData.append(`products[${variantIndex}][product_id]`, product?.id);
          formData.append(
            `products[${variantIndex}][quantity]`,
            e.quantity ? e.quantity : 1
          );
          formData.append(`products[${variantIndex}][variation_id]`, e?.id);
          formData.append(`products[${variantIndex}][ounce]`, ozValue ?? 1);
          variantIndex++;
        });
      } else {
        console.error('Invalid variations data', product?.variations);
      }
    });
    axios
      .post("https://puregoldapi.dev-mn.xyz/api/user/check/out/post", formData)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setcarts([])
          console.log(res.data.message, "message--->");
          toast.success(res.data.message);
          setShowCheckoutForm(false);
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        setShowCheckoutForm(false);

        const errorMessage = err.response?.data?.message;

        if (Array.isArray(errorMessage)) {
          // Find the "low stock" message dynamically
          const lowStockMessage = errorMessage.find((msg) =>
            msg.toLowerCase().includes("low stock")
          );

          if (lowStockMessage) {
            toast((t) => (
              <span>
                ⚠️
                <b style={{ fontSize: "12px" }}>
                  {lowStockMessage} {/* Dynamically display the low stock message */}
                </b>
              </span>
            ));
          }
        } else if (typeof errorMessage === "string") {
          toast.error(errorMessage); // For string messages
        } else {
          toast.error("An unexpected error occurred."); // Fallback for unexpected cases
        }

        console.log(
          "Error in POST API:",
          err.response ? err.response.data.message : err.message
        );
      });


  };

  const handleDelete = (variationId) => {
    const updatedCarts = carts
      .map((item) => ({
        ...item,
        variations: item.variations.filter(
          (variation) => variation?.id !== variationId
        ),
      }))
      .filter((item) => item.variations.length > 0); // Remove products with no variations
    setcarts(updatedCarts);
  };

  const handleQuantityChange = (cartId, variationId, newQuantity) => {
    setcarts((prevCarts) =>
      prevCarts.map((cart) => {
        if (cart.id === cartId) {
          return {
            ...cart,
            variations: cart.variations.map((variation) => {
              if (variation?.id === variationId) {
                return { ...variation, quantity: newQuantity };
              }
              return variation;
            }),
          };
        }
        return cart;
      })
    );
  };

  const handleOzChange = (cartId, variationId, newOzValue) => {
    setcarts((prevCarts) =>
      prevCarts?.map((cart) => {
        if (cart.id === cartId) {
          return {
            ...cart,
            variations: cart.variations.map((variation) => {
              if (variation.id === variationId) {
                return { ...variation, ozInput: newOzValue };
              }
              return variation;
            }),
          };
        }
        return cart;
      })
    );
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-full max-w-[80%] rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-2xl font-semibold text-black">
            {showCheckoutForm ? "Checkout" : "Variations"}
          </h2>
          <button
            onClick={handleClose}
            className="text-gray-600 hover:text-gray-900 font-semibold mt-5 "
          >
            &times;
          </button>
        </div>

        {showCheckoutForm ? (
          <div className="mt-4">
            <form className="space-y-4" onSubmit={handleFormSubmit}>
              <div>
                <label className="block mb-1 font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full border text-black border-gray-300 rounded-lg p-3 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full border text-black border-gray-300 rounded-lg p-3 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-700">
                  Phone
                </label>
                <input
                  type="tel"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full border text-black border-gray-300 rounded-lg p-3 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div className="flex justify-between items-center mt-4">
                <button
                  type="button"
                  onClick={handleBackToCart}
                  className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                >
                  Back to Cart
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
                >
                  {loading ? "Loading..." : "Confirm Checkout"}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="overflow-y-auto max-h-80 mt-4">
            {carts.length > 0 ? (
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-2 text-left text-black">
                      Product Id
                    </th>
                    <th className="px-4 py-2 text-left text-black">
                      Variations Id
                    </th>
                    <th className="px-4 py-2 text-left text-black">
                      Variations Name
                    </th>
                    <th className="px-4 py-2 text-left text-black">Quantity</th>
                    <th className="px-4 py-2 text-left text-black">OZ </th>
                    <th className="px-4 py-2 text-left text-black">Price</th>
                    <th className="px-4 py-2 text-left text-black">
                      Total Price
                    </th>
                    <th className="px-4 py-2 text-left text-black">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {carts?.map((cart) =>
                    cart?.variations?.map((variation) => {
                      const checkCondition = Math.floor(variation?.oz) === 0 ? false : true;
                      const ozInput = variation.ozInput;
                      const total = (variation.quantity * parseFloat(variation.price) || parseFloat(variation.price)) + (parseFloat(ozInput || 1) * parseFloat(variation.oz || 0));

                      return <tr key={variation?.id}>
                        <td className="px-4 py-2 text-black">{cart.id}</td>
                        <td className="px-4 py-2 text-black">
                          {variation?.id}
                        </td>
                        <td className="px-4 py-2 text-black">
                          {variation?.value}
                        </td>
                        <td className="px-4 py-2 text-black">
                          <input
                            type="number"
                            value={variation?.quantity || 1}
                            onChange={(e) =>
                              handleQuantityChange(
                                cart.id,
                                variation?.id,
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="w-16 border-gray-300 text-black rounded p-1"
                          />
                        </td>
                        <td className="px-4 py-2 text-black">
                          {checkCondition && <input
                            type="number"
                            value={ozInput}
                            onChange={(e) => {
                              handleOzChange(cart.id, variation.id, parseFloat(e.target.value))
                            }}
                            onBlur={() => {
                              if (isNaN(ozInput) || ozInput === 0 || ozInput === undefined || ozInput === '') {
                                handleOzChange(cart.id, variation.id, 1);
                              }
                            }}
                            min="1"
                            defaultValue="1"
                            className="w-16 border-gray-300 text-black rounded p-1"
                          />}
                        </td>

                        <td className="px-4 py-2 text-black">
                          {variation?.price}
                        </td>
                        <td className="px-4 py-2 text-black">
                          {total}
                        </td>
                        <td className="px-4 py-2 text-black">
                          <button
                            onClick={() => handleDelete(variation?.id)}
                            className="text-white py-2 px-4 hover:bg-red-500 rounded-md bg-red-700"
                            type="button"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    })
                  )}
                </tbody>
              </table>
            ) : (
              <p className="text-center py-4">Your cart is empty.</p>
            )}
          </div>
        )}

        {!showCheckoutForm && (
          <div className="flex justify-between items-center border-t pt-4 mt-4">
            <div className="ms-auto">
              <button
                onClick={handleClose}
                className="bg-gray-500 text-white py-2 px-4 rounded-lg mr-2 hover:bg-gray-600"
              >
                Continue Shopping
              </button>
              <button
                onClick={handleCheckoutClick}
                className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
              >
                Checkout
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartModal;
