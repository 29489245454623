/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import homeImag1 from "../assets/misc images/aromatherapy_Panda.jpg";
// import homeImag2 from "../assets/Diffusers/lavender-oil.jpg";
// import homeImag3 from "../assets/smuding-sage-and-bowl/Rose-Petals Blue-Sage.jpg";
// import homeImag4 from "../assets/smuding-sage-and-bowl/Dragons_Blood_Smudge_Bundle.jpg";
// import homeImag5 from "../assets/smuding-sage-and-bowl/Sage and Bowl.jpeg";
// import homeImag6 from "../assets/misc images/BodyOils-FP.png";
// import homeImag7 from "../assets/Diffusers/Diffusers.jpg";
// import homeImag8 from "../assets/smuding-sage-and-bowl/Smudging-with-Sage.jpg";
import api from "../Axios/AxiosInterceptor";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../Components/Footer";
const WholeSal = () => {
  const { wholeId } = useParams();
  const [headerText, setHeaderText] = useState("");
  const [topHeaderText, settopHeaderText] = useState("");
  const [image_text, setimage_text] = useState("");
  const [sect2WholeTittle, setsect2WholeTittle] = useState("");
  const [contentTxt, contentTxtS2] = useState("");
  const [downheading, setDownHeading] = useState("");
  const [headingws2, headingWs2] = useState("");
  const [showimage, setshowimage] = useState([]);
  const [showMainbgImage, setMainBgImage] = useState("");
  const [PageStyle, setPageStyle] = useState("");
  const pageName = localStorage.getItem("pageName");
  // Fetch Background Image URL from API
  const fetchStylePage = async () => {
    console.log(pageName, "pageName--->");
    const formdata = new FormData();
    formdata.append("name", pageName);

    try {
      const response = await axios.post(
        `https://puregoldapi.dev-mn.xyz/api/user/get/dynamic/page/layout`,
        formdata
      );

      const data = response?.data?.data; // Axios already parses the response as JSON
      console.log("bgimagresponsee--->", data);

      if (data) {
        // Assuming your API response contains a "file_path" field
        setPageStyle(data);
        // You can use the response to set the style as needed
      }
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchStylePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName]);
  const fetchBackgroundImage = async () => {
    try {
      const response = await fetch(
        `https://puregoldapi.dev-mn.xyz/api/user/get/dynamic/whole/sale/bg/id/${wholeId}`
      ); // Replace with your API URL
      const data = await response.json();
      setMainBgImage(data?.data?.file_path); // Assuming your API response contains an "imageUrl" field
      console.log("bgimage--->", data?.data?.file_path);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchBackgroundImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wholeId]);
  console.log("showImage", showimage);

  const fetchHeaderData = async () => {
    try {
      const response = await axios.get(
        `https://puregoldapi.dev-mn.xyz/api/user/get/dynamic/whole/sale/section/one/dynamic/page/id/${wholeId}`
      );
      if (response) {
        console.log("fetching header data:", response?.data?.data);
        setHeaderText(response?.data?.data[0]?.title); // Assuming `message` contains the text
        setimage_text(response?.data?.data[0]?.image_text); // Assuming `message` contains the text
        setshowimage(response?.data?.data[0]?.images); // Assuming `message` contains the text
        // console.log(response.data.data[0]);
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }
  };
  const fetchDatasection2W = async () => {
    try {
      const response = await axios.get(
        `https://puregoldapi.dev-mn.xyz/api/user/get/dynamic/whole/sale/section/two/dynamic/page/id/${wholeId}`
      );
      if (response) {
        setsect2WholeTittle(response?.data?.data?.title); // Assuming `message` contains the text
        contentTxtS2(response?.data?.data?.content_text); // Assuming `message` contains the text
        setDownHeading(response?.data?.data?.down_heading); // Assuming `message` contains the text
        headingWs2(response?.data?.data?.heading); // Assuming `message` contains the text
        console.log("sssdccc", response.data);
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }
  };

  useEffect(() => {
    fetchHeaderData();
    fetchDatasection2W();

    api
      .get(
        `https://puregoldapi.dev-mn.xyz/api/user/get/dynamic/whole/sale/page/top/header/${wholeId}`
      )
      .then((res) => {
        settopHeaderText(res.data.data.title);
        console.log(res.data.data, "title===>");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [wholeId]);
  console.log(PageStyle, "PageStyle--->");
  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundImage: `url(${showMainbgImage})`,
      }}
    >
      <div
        // style={{
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "100% 100%",
        //   backgroundImage: `url(${homeImag8})`,
        // }}
        className="mb-0 pb-10"
      >
        <div
          className={`w-full text-center ${
            Array.isArray(PageStyle) &&
            PageStyle?.find(
              (style) => style?.type === "DynamicpageTopHeadrTitle"
            )
              ? `bg-${
                  PageStyle.find(
                    (style) => style?.type === "DynamicpageTopHeadrTitle"
                  )?.background
                }`
              : "bg-[#693948]"
          }`}
          style={{
            padding: "16px",
          }}
        >
          {topHeaderText && (
            <p
              style={{
                fontFamily:
                  Array.isArray(PageStyle) &&
                  PageStyle.find((style) => style?.type === "Dynamicpage1Title")
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage1Title"
                      )?.font_family
                    : "", // Default font-family in case it's not found
                fontSize:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "DynamicpageTopHeadrTitle"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "DynamicpageTopHeadrTitle"
                      )?.font_size
                    : "35px",
                marginTop: 100,
                color:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "DynamicpageTopHeadrTitle"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "DynamicpageTopHeadrTitle"
                      )?.color
                    : "black",
              }}
              className={`sm:text-xl md:text-${
                Array.isArray(PageStyle) &&
                PageStyle?.find(
                  (style) => style?.type === "DynamicpageTopHeadrTitle"
                )
                  ? PageStyle.find(
                      (style) => style?.type === "DynamicpageTopHeadrTitle"
                    )?.font_size
                  : "3xl"
              } font-bold`}
            >
              {topHeaderText}
            </p>
          )}
        </div>

        {headerText && (
          <div
            className={`w-full text-center ${
              Array.isArray(PageStyle) &&
              PageStyle?.find((style) => style?.type === "Dynamicpage1Title")
                ? `bg-${
                    PageStyle.find(
                      (style) => style?.type === "Dynamicpage1Title"
                    )?.background
                  }`
                : "bg-[#68ADC2]"
            } mt-5 md:mt-0 p-5`}
          >
            <p
              style={{
                fontFamily:
                  Array.isArray(PageStyle) &&
                  PageStyle.find((style) => style?.type === "Dynamicpage1Title")
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage1Title"
                      )?.font_family
                    : "", // Default font-family in case it's not found
                fontSize:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "Dynamicpage1Title"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage1Title"
                      )?.font_size
                    : "35px",
                color:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "Dynamicpage1Title"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage1Title"
                      )?.color
                    : "black",
              }}
              className={`sm:text-xl md:text-${
                Array.isArray(PageStyle) &&
                PageStyle?.find((style) => style?.type === "Dynamicpage1Title")
                  ? PageStyle.find(
                      (style) => style?.type === "Dynamicpage1Title"
                    )?.font_size
                  : "3xl"
              } font-bold`}
            >
              {headerText}
            </p>
          </div>
        )}

        {image_text && (
          <h2
          style={{
            fontFamily:
              Array.isArray(PageStyle) &&
              PageStyle.find((style) => style?.type === "Dynamicpage1ImageText")
                ? PageStyle.find(
                    (style) => style?.type === "Dynamicpage1ImageText"
                  )?.font_family
                : "", // Default font-family in case it's not found
            fontSize:
              Array.isArray(PageStyle) &&
              PageStyle?.find(
                (style) => style?.type === "Dynamicpage1ImageText"
              )
                ? PageStyle.find(
                    (style) => style?.type === "Dynamicpage1ImageText"
                  )?.font_size
                : "35px",
            color:
              Array.isArray(PageStyle) &&
              PageStyle?.find(
                (style) => style?.type === "Dynamicpage1ImageText"
              )
                ? PageStyle.find(
                    (style) => style?.type === "Dynamicpage1ImageText"
                  )?.color
                : "#E36A63",
          }}
            className={`text-xl md:text-${
              Array.isArray(PageStyle) &&
              PageStyle?.find(
                (style) => style?.type === "Dynamicpage1ImageText"
              )
                ? PageStyle.find(
                    (style) => style?.type === "Dynamicpage1ImageText"
                  )?.font_size
                : "3xl"
            } font-bold text-center mt-10 mb-5`}
          >
            {image_text}
          </h2>
        )}

        <div className="relative grid grid-cols-12 overflow-hidden">
          {showimage?.slice(0, 4).map((img, index) => {
            // Check if it's a video or image
            const isVideo = img?.file_name === null; // Assuming null file_name indicates a video
            const videoUrl = img?.file_path;

            // Function to handle different video platforms
            const getEmbedUrl = (url) => {
              if (!url) return null;

              if (url.includes("youtu.be") || url.includes("youtube.com")) {
                const videoId =
                  url?.split("youtu.be/")[1] ||
                  url?.split("v=")[1]?.split("&")[0];
                return `https://www.youtube.com/embed/${videoId}`;
              }

              if (url.includes("vimeo.com")) {
                const videoId = url.split("vimeo.com/")[1];
                return `https://player.vimeo.com/video/${videoId}`;
              }

              if (url.match(/\.(mp4|webm|ogg)$/i)) {
                return url; // Direct video link
              }

              return null;
            };

            const embedUrl = isVideo ? getEmbedUrl(videoUrl) : null;

            return (
              <div
                key={index}
                className={`py-4 px-2 ${
                  index === 0 || index === 1
                    ? "col-span-6" // First two items take 6 columns
                    : showimage?.length === 3
                    ? "col-span-12" // Third item takes the entire row (12 columns)
                    : "col-span-6" // Default case
                }`}
                style={{
                  maxHeight: "300px",
                  overflow: "hidden",
                }}
              >
                {isVideo ? (
                  embedUrl ? (
                    embedUrl.includes("youtube.com") ||
                    embedUrl.includes("vimeo.com") ? (
                      <iframe
                        src={embedUrl}
                        title={`Video Player ${index}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        width="100%"
                        height="300px"
                        style={{ objectFit: "initial" }}
                      ></iframe>
                    ) : (
                      <video
                        src={embedUrl}
                        width="100%"
                        height="300px"
                        controls
                        autoPlay
                        muted
                        style={{ objectFit: "initial" }}
                      >
                        Your browser does not support the video tag.
                      </video>
                    )
                  ) : (
                    <p>Invalid video link</p>
                  )
                ) : (
                  <img
                    className="w-full h-auto max-h-[100%] object-contain"
                    src={img?.file_path}
                    alt=""
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="relative    py-7 px-4">
        <div className="absolute inset-0 z-0"></div>
      </div> */}
      <div
        className="w-full"
        // style={{
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "100% 100%",
        //   backgroundImage: `url(${showMainbgImage})`,
        // }}
      >
        <div className="relative py-12">
          <div className="mx-auto px-4 grid grid-cols-1 md:grid-cols-1 gap-8 items-start">
            {/* Welcome Text */}
            <div
              className={`bg-${
                Array.isArray(PageStyle) &&
                PageStyle.find((style) => style?.type === "Dynamicpage2Title")
                  ? PageStyle.find(
                      (style) => style?.type === "Dynamicpage2Title"
                    )?.background
                  : "[#5F374E]"
              } opacity-90 w-full mt-8 md:mt-[33px] text-[16px] lg:w-[100%] backdrop-blur p-4 md:p-12 text-white mx-auto md:mx-0`}
            >
              <h1
              style={{
                fontFamily:
                  Array.isArray(PageStyle) &&
                  PageStyle.find((style) => style?.type === "Dynamicpage2Title")
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2Title"
                      )?.font_family
                    : "", // Default font-family in case it's not found
                fontSize:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "Dynamicpage2Title"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2Title"
                      )?.font_size
                    : "35px",
                color:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "Dynamicpage2Title"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2Title"
                      )?.color
                    : "white",
              }}
                className={`md:text-${
                  Array.isArray(PageStyle) &&
                  PageStyle.find((style) => style?.type === "Dynamicpage2Title")
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2Title"
                      )?.font_size
                    : "5xl"
                }
                  text-3xl font-bold text-center tracking-wide`}

              >
                {sect2WholeTittle}
              </h1>
              <h1  
               style={{ // Default font-family in case it's not found
                fontFamily:
                  Array.isArray(PageStyle) &&
                  PageStyle.find((style) => style?.type === "Dynamicpage2Heading")
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2Heading"
                      )?.font_family
                    : "", // Default font-family in case it's not found
                fontSize:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "Dynamicpage2Heading"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2Heading"
                      )?.font_size
                    : "35px",
                color:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "Dynamicpage2Heading"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2Heading"
                      )?.color
                    : "#E4ABCD",
              }}
              className=" sm:text-2xl font-bold mt-4 md:mt-[18px]">
                {headingws2}
              </h1>
              <h1
              style={{ 
                fontFamily:
                  Array.isArray(PageStyle) &&
                  PageStyle.find((style) => style?.type === "Dynamicpage2ContentText")
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2ContentText"
                      )?.font_family
                    : "", // Default font-family in case it's not found
                fontSize:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "Dynamicpage2ContentText"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2ContentText"
                      )?.font_size
                    : "35px",
                color:
                  Array.isArray(PageStyle) &&
                  PageStyle?.find(
                    (style) => style?.type === "Dynamicpage2ContentText"
                  )
                    ? PageStyle.find(
                        (style) => style?.type === "Dynamicpage2ContentText"
                      )?.color
                    : "#E4ABCD",
              }} 
               className="text-pink-300 text-2xl md:text-[38px] font-bold mt-4 md:mt-[18px]">
                {contentTxt}
              </h1>
              {/* <p className="text-base md:text-[18px] leading-6 md:leading-[38px] pt-4 md:pt-[26px] pb-4 md:pb-[26px] mx-2 md:mx-[7px]">
                {contentTxt}
              </p> */}
              <div className="text-justify">
                <p
                style={{ 
                  background:
                    Array.isArray(PageStyle) &&
                    PageStyle.find((style) => style?.type === "Dynamicpage2DownHeading")
                      ? PageStyle.find(
                          (style) => style?.type === "Dynamicpage2DownHeading"
                        )?.background
                      : "#693948", // Default font-family in case it's not found
                  fontFamily:
                    Array.isArray(PageStyle) &&
                    PageStyle.find((style) => style?.type === "Dynamicpage2DownHeading")
                      ? PageStyle.find(
                          (style) => style?.type === "Dynamicpage2DownHeading"
                        )?.font_family
                      : "", // Default font-family in case it's not found
                  fontSize:
                    Array.isArray(PageStyle) &&
                    PageStyle?.find(
                      (style) => style?.type === "Dynamicpage2DownHeading"
                    )
                      ? PageStyle.find(
                          (style) => style?.type === "Dynamicpage2DownHeading"
                        )?.font_size
                      : "18px",
                  color:
                    Array.isArray(PageStyle) &&
                    PageStyle?.find(
                      (style) => style?.type === "Dynamicpage2DownHeading"
                    )
                      ? PageStyle.find(
                          (style) => style?.type === "Dynamicpage2DownHeading"
                        )?.color
                      : "white",
                      lineHeight: 3
                }}
                  className=" text-sm md:text-base  py-3 px-4 leading-[40px]"
                >
                  {downheading}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <footer className="bg-[#6E8787] text-white py-10">
        <div className="container mx-auto flex flex-col items-center">
          <div className="flex space-x-6 mb-4">
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="YouTube"
            >
              <FaYoutube />
            </a>
          </div>
          <p className="text-lg font-semibold">Pure Gold Fragrances</p>
          <div className="flex space-x-8 mt-4">
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Home
            </a>
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Wholesale
            </a>
          </div>
        </div>
      </footer> */}
      <Footer />
    </div>
  );
};

export default WholeSal;
