/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
// import homeImag1 from "../assets/misc images/aromatherapy_Panda.jpg";
// import homeImag2 from "../assets/Diffusers/lavender-oil.jpg";
// import homeImag3 from "../assets/smuding-sage-and-bowl/Rose-Petals Blue-Sage.jpg";
// import homeImag4 from "../assets/smuding-sage-and-bowl/Dragons_Blood_Smudge_Bundle.jpg";
// import homeImag5 from "../assets/smuding-sage-and-bowl/Sage and Bowl.jpeg";
// import homeImag6 from "../assets/misc images/BodyOils-FP.png";
// import homeImag7 from "../assets/Diffusers/Diffusers.jpg";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import api from "../Axios/AxiosInterceptor";
import Footer from "../Components/Footer";
// import { ThemeContext } from "../context/CardContext";

const Home = () => {
  // const products = [
  //   {
  //     title: "Body Oils",
  //     image: homeImag6, // Replace with your image path
  //     bgColor: "text-pink-500",
  //   },
  //   {
  //     title: "Incense/Accessories",
  //     image: homeImag7, // Replace with your image path
  //     bgColor: "text-pink-500",
  //   },
  //   {
  //     title: "Diffusers-Oils",
  //     description: "Buy 5 Get One Free. Lamps 1 for $15 and 2 for $25",
  //     image: homeImag2, // Replace with your image path
  //     bgColor: "text-red-500",
  //   },
  //   {
  //     title: "Health Care",
  //     description: "Click on the image to browse our selection.",
  //     image: homeImag4, // Replace with your image path
  //     bgColor: "text-green-500",
  //   },
  // ];
  const [sections, setSections] = useState({
    section1: [],
    section2: [],
    section3: [],
    section4: [],
    section5: [],
    section6: [],
    section7: [],
  });
  const [bgImage, setBGImage] = useState("");
  const [Topheader, setTopheader] = useState("");
  const [pagestyle, setpagestyle] = useState([]);

  const [loader, setLoader] = useState(false);

  const fetchAllSections = async () => {
    setLoader(true);
    try {
      const [res1, res2, res3, res4, res5, res6, res7] = await Promise.all([
        api.get("admin/get/home/page/section/one"),
        api.get("admin/get/home/page/section/two"),
        api.get("admin/get/home/page/section/three"),
        api.get("admin/get/home/page/section/four"),
        api.get("admin/get/home/page/section/five"),
        api.get("admin/get/home/page/section/six"),
        api.get("admin/get/home/page/section/seven"),
      ]);
      setSections({
        section1: res1.data,
        section2: res2.data,
        section3: res3.data,
        section4: res4.data,
        section5: res5.data,
        section6: res6.data,
        section7: res7.data,
      });
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
    setLoader(false);
  };

  const fetchpagestyle = async () => {
    setLoader(true);
    try {
      const response = await api.get("user/get/home/layout");
      setpagestyle(response?.data?.data);
      console.log(response?.data?.data, "layout--->");
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
    setLoader(false);
  };
  const fetchBGImage = async () => {
    setLoader(true);
    try {
      const response = await api.get("admin/get/home/bg");
      setBGImage(response?.data?.data);
      console.log(response, "response");
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
    setLoader(false);
  };
  const fetchTopHeader = async () => {
    setLoader(true);
    try {
      const response = await api.get("admin/get/home/page/top/header");
      setTopheader(response?.data?.data?.title);
      console.log(response, "responseheader");
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchAllSections();
    fetchBGImage();
    fetchpagestyle();
    fetchTopHeader()
  }, []);

  const getEmbedUrl = (url) => {
    const videoId =
      url?.split("youtu.be/")[1] || url?.split("v=")[1]?.split("&")[0];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  if (loader) {
    return <div>Loading...</div>;
  }
  console.log(Topheader, "pagestyle--");
  return (
    <div className="">
      <div className="h-[570px]">
        <div
          className="mb-0"
          style={{
            padding: "16px",
            textAlign: "center",
            backgroundColor: "#693948",
          }}
        ></div>
        <div className="mb-0 mt-10">
        <div className="relative text-center">
              {/* Section One Top Heading */}
              <div
                className={`py-6 px-4 ${
                  !pagestyle.find(
                    (style) => style.type === "topHeader"
                  )?.background
                    ? "bg-white" // Default background if not found
                    : ""
                } ${
                  pagestyle.find(
                    (style) => style.type === "topHeader"
                  )?.color
                    ? "text-black" // Default text color if color is found
                    : ""
                }`}
                style={{
                  ...(pagestyle?.find(
                    (style) => style?.type === "topHeader"
                  ) && {
                    background: pagestyle.find(
                      (style) => style.type === "topHeader"
                    )?.background,
                  }),
                }}
              >
                <p 
                 style={{
                  ...(pagestyle?.find(
                    (style) => style?.type === "topHeader"
                  ) && {
                    color:
                      pagestyle.find(
                        (style) => style.type === "topHeader"
                      )?.color || "#000", // Default color
                    fontSize: `${
                      pagestyle.find(
                        (style) => style.type === "topHeader"
                      )?.font_size || 16 // Default font size
                    }px`,
                    fontFamily:
                      pagestyle.find(
                        (style) => style.type === "topHeader"
                      )?.font_family || "Arial", // Default font family
                  }),
                }}
                className="sm:text-xl  max-w-4xl mx-auto leading-relaxed">
                  {Topheader}
                </p>
              </div>
            </div>
          <div className="relative flex items-center justify-center">
            <div className="absolute inset-0 z-0 h-[400px]">
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                {sections.section1.length !== 0 &&
                  sections.section1.data[0]?.images.map((i) => (
                    <SwiperSlide>
                      <img src={i.file_path} alt="not ok" />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <div className="absolute inset-0" />
            </div>
            <div className="relative z-10 mt-32 text-center">
              {/* Section One Top Heading */}
              <div
                className={`py-6 px-4 ${
                  !pagestyle.find(
                    (style) => style.type === "sectiononeTopHeading"
                  )?.background
                    ? "bg-white" // Default background if not found
                    : ""
                } ${
                  pagestyle.find(
                    (style) => style.type === "sectiononeTopHeading"
                  )?.color
                    ? "text-black" // Default text color if color is found
                    : ""
                }`}
                style={{
                  ...(pagestyle?.find(
                    (style) => style?.type === "sectiononeTopHeading"
                  ) && {
                    background: pagestyle.find(
                      (style) => style.type === "sectiononeTopHeading"
                    )?.background, // Default background
                    color:
                      pagestyle.find(
                        (style) => style.type === "sectiononeTopHeading"
                      )?.color || "#000", // Default color
                    fontSize: `${
                      pagestyle.find(
                        (style) => style.type === "sectiononeTopHeading"
                      )?.font_size || 16 // Default font size
                    }px`,
                    fontFamily:
                      pagestyle.find(
                        (style) => style.type === "sectiononeTopHeading"
                      )?.font_family || "Arial", // Default font family
                  }),
                }}
              >
                <p className="text-xl md:text-2xl max-w-4xl mx-auto leading-relaxed">
                  {sections.section1.length !== 0 &&
                    sections.section1.data[0]?.title}
                </p>
              </div>

              {/* Section One Title */}
              <div
                style={{
                  ...(pagestyle?.find(
                    (style) => style?.type === "sectiononeTitle"
                  ) && {
                    background:
                      pagestyle.find(
                        (style) => style.type === "sectiononeTitle"
                      )?.background || "#f9f9f9", // Default background color
                    color:
                      pagestyle.find(
                        (style) => style.type === "sectiononeTitle"
                      )?.color || "#333", // Default text color
                    fontSize: `${
                      pagestyle.find(
                        (style) => style.type === "sectiononeTitle"
                      )?.font_size || 18 // Default font size
                    }px`,
                    fontFamily:
                      pagestyle.find(
                        (style) => style.type === "sectiononeTitle"
                      )?.font_family || "Verdana", // Default font family
                  }),
                }}
                className="backdrop-blur py-8 px-4"
              >
                <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center gap-4">
                  <div className="sm:text-2xl">
                    {sections.section1.length !== 0 &&
                      sections.section1.data[0]?.below_top_heading}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          ...(pagestyle?.find((style) => style?.type === "sectiontwoTitle") && {
            background: pagestyle.find(
              (style) => style.type === "sectiontwoTitle"
            )?.background, // Default to transparent if background is missing
          }),
        }}
        className={
          !pagestyle.find((style) => style.type === "sectiontwoTitle")
            ?.background
            ? "relative py-7 px-4 bg-gray-200" // Default background color
            : "relative py-7 px-4"
        }
      >
        <div className="absolute inset-0 z-0"></div>

        <div className="relative z-10 max-w-7xl mx-auto text-center space-y-4">
          <h1
            style={{
              ...(pagestyle?.find(
                (style) => style?.type === "sectiontwoHeading"
              ) && {
                color:
                  pagestyle.find((style) => style.type === "sectiontwoHeading")
                    ?.color || "black", // Default to black
                fontSize: `${
                  pagestyle.find((style) => style.type === "sectiontwoHeading")
                    ?.font_size || 24 // Default font size
                }px`,
                fontFamily:
                  pagestyle.find((style) => style.type === "sectiontwoHeading")
                    ?.font_family || "Arial", // Default font family
              }),
            }}
            className="sm:text-4xl font-bold tracking-wide"
          >
            {sections.section2 && sections.section2.data?.heading}
          </h1>
          <p
            style={{
              ...(pagestyle?.find(
                (style) => style?.type === "sectiontwoTitle"
              ) && {
                color:
                  pagestyle.find((style) => style.type === "sectiontwoTitle")
                    ?.color || "gray-700", // Default to gray
                fontSize: `${
                  pagestyle.find((style) => style.type === "sectiontwoTitle")
                    ?.font_size || 18 // Default font size
                }px`,
                fontFamily:
                  pagestyle.find((style) => style.type === "sectiontwoTitle")
                    ?.font_family || "Verdana", // Default font family
              }),
            }}
            className="text-3xl md:text-4xl font-medium"
          >
            {sections.section2 && sections.section2.data?.title}
          </p>
        </div>
      </div>

      {/* <div className="relative bg-gradient-to-b from-lime-200/40 to-lime-100/40 py-12"> */}
      <div
        style={{
          backgroundImage: `url("${bgImage?.file_path}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className={`relative py-12`}
      >
        <div className=" mx-auto px-4 grid grid-cols-1  md:grid-cols-2 gap-8 items-center">
          <div
            style={{
              ...(pagestyle?.find(
                (style) => style?.type === "sectionThreeContentText"
              ) && {
                background: pagestyle.find(
                  (style) => style.type === "sectionThreeContentText"
                )?.background,
                color: pagestyle.find(
                  (style) => style.type === "sectionThreeContentText"
                )?.color,
                fontSize: `${
                  pagestyle.find(
                    (style) => style.type === "sectionThreeContentText"
                  )?.font_size
                }px`,
                fontFamily: pagestyle.find(
                  (style) => style.type === "sectionThreeContentText"
                )?.font_family,
              }),
            }}
            className={`backdrop-blur md:p-8 p-2 ${
              !pagestyle.find(
                (style) => style.type === "sectionThreeContentText"
              )?.background && "bg-green-600"
            }`}
          >
            <p
              style={{ wordSpacing: "10px" }}
              className="text-base leading-[38px] pt-[26px] pb-[26px] mx-[7px]"
            >
              {sections.section3 && sections.section3.data?.content}
            </p>
          </div>
          <div className="space-y-6">
            <div className="relative">
              <iframe
                // width="540"
                width={"100%"}
                height="315"
                src={getEmbedUrl(sections?.section3?.data?.video_link)}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div>
              <img
                src={sections.section3 && sections.section3.data?.file_path}
                alt="Essential Oils with Lavender"
                className="w-full object-cover"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            ...(pagestyle?.find(
              (style) => style?.type === "sectionFourTitle"
            ) && {
              background:
                pagestyle.find((style) => style.type === "sectionFourTitle")
                  ?.background || "white", // Default to 'transparent' if no background is defined
            }),
          }}
          className={
            pagestyle.find((style) => style.type === "sectionFourTitle")
              ?.background
              ? "container grid grid-cols-1 md:grid-cols-2 flex-row mx-auto md:px-[66px] px-6 md:py-[110px] py-[60px]"
              : "bg-white container grid grid-cols-1 md:grid-cols-2 flex-row mx-auto md:px-[66px] px-6 md:py-[124px] py-[60px]"
          }
        >
          <div>
            <h1
              style={{
                ...(pagestyle?.find(
                  (style) => style?.type === "sectionFourTitle"
                ) && {
                  color: pagestyle.find(
                    (style) => style.type === "sectionFourTitle"
                  )?.color,
                  fontSize: `${
                    pagestyle.find((style) => style.type === "sectionFourTitle")
                      ?.font_size || 24 // Default font size
                  }px`,
                  fontFamily:
                    pagestyle.find((style) => style.type === "sectionFourTitle")
                      ?.font_family || "Arial", // Default font family
                }),
                lineHeight: "63px", // Static line height
              }}
              className="sm:text-4xl font-bold"
            >
              {sections.section4 && sections.section4.data?.title}
            </h1>
          </div>
          <div class="w-full">
            <iframe
              // style={{ marginLeft: "246px", width: "535px", height: "323px" }}
              class="w-full h-[270px] md:h-[360px] rounded-lg shadow-lg"
              src={getEmbedUrl(sections?.section4?.data?.video_link)}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="md:px-2 mt-10">
          <div
            style={{
              ...(pagestyle?.find(
                (style) => style?.type === "sectionFourContentText"
              ) && {
                background: pagestyle.find(
                  (style) => style.type === "sectionFourContentText"
                )?.background, // Dynamic background
                color: pagestyle.find(
                  (style) => style.type === "sectionFourContentText"
                )?.color,
                fontSize: `${
                  pagestyle.find(
                    (style) => style.type === "sectionFourContentText"
                  )?.font_size || 16 // Default font size
                }px`,
                fontFamily:
                  pagestyle.find(
                    (style) => style.type === "sectionFourContentText"
                  )?.font_family || "Arial", // Default font family
              }),
              lineHeight: "93px", // Static line height
            }}
            className={`${
              !pagestyle.find(
                (style) => style.type === "sectionFourContentText"
              )?.background
                ? "bg-[#F77E8C]"
                : ""
            } md:mx-[144px] -mt-[22px] md:p-[58px] px-2 rounded-lg shadow-lg`}
          >
            <p
              className={`${
                !pagestyle.find(
                  (style) => style.type === "sectionFourContentText"
                )?.color
                  ? "text-white"
                  : ""
              } text-lg leading-[44px] mb-6`}
            >
              {sections.section4 && sections.section4.data?.content}
            </p>
          </div>
        </div>
        <div
          style={{ gap: "27px" }}
          className="bg-white  md:pt-[83px] md:p-[55px] grid grid-cols-1 md:grid-cols-3  px-3 gap-9 mt-[84px] "
        >
          {sections.section5 &&
            sections.section5.data?.map((d) => (
              <a href={d?.link}>
                <img
                  style={{ width: "100%", height: "222px" }}
                  key={d.id}
                  src={d.file_path}
                  alt=""
                />
              </a>
            ))}
        </div>
        <div
          className={`relative py-12 px-6 ${
            pagestyle?.find((style) => style?.type === "sectionSixBackground")
              ? pagestyle.find(
                  (style) => style?.type === "sectionSixBackground"
                )?.background
              : "bg-green-100"
          }`}
        >
          <h1
            className={`text-4xl md:text-5xl font-bold text-center ${
              pagestyle?.find((style) => style?.type === "sectionSixTitle")
                ?.color
                ? pagestyle.find((style) => style?.type === "sectionSixTitle")
                    ?.color
                : "text-red-800"
            } mb-8`}
            style={{
              ...(pagestyle?.find(
                (style) => style?.type === "sectionSixTitle"
              ) && {
                fontSize: `${
                  pagestyle.find((style) => style?.type === "sectionSixTitle")
                    ?.font_size
                }px`,
                fontFamily: pagestyle.find(
                  (style) => style?.type === "sectionSixTitle"
                )?.font_family,
              }),
            }}
          >
            {sections.section6 && sections.section6.data?.title}
          </h1>
          <p
            className={`text-lg md:text-[24px] md:px-[77px] leading-snug mb-8 ${
              pagestyle?.find(
                (style) => style?.type === "sectionSixContentText"
              )?.color
                ? pagestyle.find(
                    (style) => style?.type === "sectionSixContentText"
                  )?.color
                : "text-gray-800"
            }`}
            style={{
              ...(pagestyle?.find(
                (style) => style?.type === "sectionSixContentText"
              ) && {
                fontSize: `${
                  pagestyle.find(
                    (style) => style?.type === "sectionSixContentText"
                  )?.font_size
                }px`,
                fontFamily: pagestyle.find(
                  (style) => style?.type === "sectionSixContentText"
                )?.font_family,
              }),
            }}
          >
            {sections.section6 && sections.section6.data?.content_text}
          </p>

          <div className="absolute bottom-0 right-0 w-1/3 md:w-1/4"></div>
        </div>

        <section className="bg-white py-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:px-32 px-5">
            <div>
              <h2
                className={`mt-4 text-[38px] md:ml-[83px] text-center mb-[33px] font-semibold ${
                  pagestyle?.find(
                    (style) => style?.type === "sectionSevenImage1text"
                  )?.color
                    ? pagestyle.find(
                        (style) => style?.type === "sectionSevenImage1text"
                      )?.color
                    : "text-pink-500"
                }`}
                style={{
                  ...(pagestyle?.find(
                    (style) => style?.type === "sectionSevenImage1text"
                  ) && {
                    fontSize: `${
                      pagestyle.find(
                        (style) => style?.type === "sectionSevenImage1text"
                      )?.font_size
                    }px`,
                    fontFamily: pagestyle.find(
                      (style) => style?.type === "sectionSevenImage1text"
                    )?.font_family,
                  }),
                }}
              >
                {sections.section7 && sections.section7.data?.image1_text}
              </h2>
              <a href={sections.section7.data?.image1_link}>
                <img
                  src={sections.section7 && sections.section7.data?.file_path1}
                  alt="image"
                  className="h-[336px] w-full object-cover"
                />
              </a>
            </div>

            <div>
              <h2
                className={`mt-4 text-[38px] md:ml-[83px] text-center mb-[33px] font-semibold ${
                  pagestyle?.find(
                    (style) => style?.type === "sectionSevenImage2text"
                  )?.color
                    ? pagestyle.find(
                        (style) => style?.type === "sectionSevenImage2text"
                      )?.color
                    : "text-pink-500"
                }`}
                style={{
                  ...(pagestyle?.find(
                    (style) => style?.type === "sectionSevenImage2text"
                  ) && {
                    fontSize: `${
                      pagestyle.find(
                        (style) => style?.type === "sectionSevenImage2text"
                      )?.font_size
                    }px`,
                    fontFamily: pagestyle.find(
                      (style) => style?.type === "sectionSevenImage2text"
                    )?.font_family,
                  }),
                }}
              >
                {sections.section7 && sections.section7.data?.image2_text}
              </h2>
              <a href={sections.section7.data?.image2_link}>
                <img
                  src={sections.section7 && sections.section7.data?.file_path2}
                  alt="image"
                  className="h-[336px] w-full object-cover"
                />
              </a>
            </div>

            <div>
              <h2
                className={`mt-4 text-[38px] ml-[83px] mb-[33px] font-semibold ${
                  pagestyle?.find(
                    (style) => style?.type === "sectionSevenImage3text"
                  )?.color
                    ? pagestyle.find(
                        (style) => style?.type === "sectionSevenImage3text"
                      )?.color
                    : "text-pink-500"
                }`}
                style={{
                  ...(pagestyle?.find(
                    (style) => style?.type === "sectionSevenImage3text"
                  ) && {
                    fontSize: `${
                      pagestyle.find(
                        (style) => style?.type === "sectionSevenImage3text"
                      )?.font_size
                    }px`,
                    fontFamily: pagestyle.find(
                      (style) => style?.type === "sectionSevenImage3text"
                    )?.font_family,
                  }),
                }}
              >
                {sections.section7 && sections.section7.data?.image3_text}
              </h2>
              <a href={sections.section7.data?.image3_link}>
                <img
                  src={sections.section7 && sections.section7.data?.file_path3}
                  alt="image"
                  className="h-[336px] w-full object-cover"
                />
              </a>
            </div>

            <div>
              <h2
                className={`mt-4 text-[38px] ml-[83px] mb-[33px] font-semibold ${
                  pagestyle?.find(
                    (style) => style?.type === "sectionSevenImage4text"
                  )?.color
                    ? pagestyle.find(
                        (style) => style?.type === "sectionSevenImage4text"
                      )?.color
                    : "text-pink-500"
                }`}
                style={{
                  ...(pagestyle?.find(
                    (style) => style?.type === "sectionSevenImage4text"
                  ) && {
                    fontSize: `${
                      pagestyle.find(
                        (style) => style?.type === "sectionSevenImage4text"
                      )?.font_size
                    }px`,
                    fontFamily: pagestyle.find(
                      (style) => style?.type === "sectionSevenImage4text"
                    )?.font_family,
                  }),
                }}
              >
                {sections.section7 && sections.section7.data?.image4_text}
              </h2>
              <a href={sections.section7.data?.image4_link}>
                <img
                  src={sections.section7 && sections.section7.data?.file_path4}
                  alt="image"
                  className="h-[336px] w-full object-cover"
                />
              </a>
            </div>
          </div>
        </section>
      </div>
      {/* <footer className="bg-[#6E8787] text-white py-10">
        <div className="container mx-auto flex flex-col items-center">
          <div className="flex space-x-6 mb-4">
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="YouTube"
            >
              <FaYoutube />
            </a>
          </div>
          <p className="text-lg font-semibold">Pure Gold Fragrances</p>
          <div className="flex space-x-8 mt-4">
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Home
            </a>
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Wholesale
            </a>
          </div>
        </div>
      </footer> */}
      <Footer />
    </div>
  );
};

export default Home;
